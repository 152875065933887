import WalletModel from '@/views/wallet/model/WalletModel';
import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import router from '@/router';

export default class WalletMainViewModel {
  constructor() {
    this.model = new WalletModel();
    this.isWalletAdminPopup = false;
    this.isMoneyInfoPopup = false;
    this.isPointInfoPopup = false;
  }
  init(){
    this.getWalletMain();
  }
  onClickWalletAdminPopup(){
    this.isWalletAdminPopup = true;
  }
  onClickWalletAdminPopupClose(){
    this.isWalletAdminPopup = false;
  }
  onClickMoneyInfo(){
    this.isMoneyInfoPopup = true;
  }
  onClickMoneyInfoClose(){
    this.isMoneyInfoPopup = false;
  }
  onClickPointInfo(){
    this.isPointInfoPopup = true;
  }
  onClickPointInfoClose(){
    this.isPointInfoPopup = false;
  }

  onClickDetail(transaction_uid){
    let newQuery = JSON.parse(JSON.stringify(router.app._route.query));
    newQuery.userType = this.userType;
    newQuery.closeRouteName = 'WalletMainParents';
    router.push({name:'TransDetail', params:{ id : transaction_uid }, query: newQuery});
  }
  getWalletMain(){
    const path = `${apiPath.WALLET_MAIN}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data.body;
      this.model.setData(resultData)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}