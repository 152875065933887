import store from '@/store/index';
import LocalStorageManager from '@/storages/LocalStorageManager';

export default class WalletModel {
  constructor(){
    this.wallet_amount = null;
    this.money_amount = null;
    this.point_amount = null;
    this.user = {
      account_id : "",
      name : "",
      nick_name : "",
      profile_image_url : null,
      role : "",
      status : "",
      user_uid : null,
    };
    this.wallet_transaction_count = null;
    this.wallet_transaction_list = [];
  }
  setData(obj){
    const { wallet_amount, money_amount, point_amount, user, register_coupon_count, wallet_transaction_count, wallet_transaction_list } = obj;
    this.wallet_amount = wallet_amount;
    this.money_amount = money_amount;
    this.point_amount = point_amount;

    this.user = user;
    LocalStorageManager.shared.setUserData(user);
    store.dispatch('commonUserData/fetchUserData', user);

    this.register_coupon_count = register_coupon_count;
    
    this.wallet_transaction_count = wallet_transaction_count;
    this.wallet_transaction_list = wallet_transaction_list;
  }
}