<template>
  <PageWithLayout
    :isBackBtn="true"
    :isBackWebEnd="true"
    :isTitle="true"
    :title="$poData.parent_wallet_main_title.format(viewModel.model.user.name)"
    :isRightTextMenu="true"
    :rightTextMenu="$poData.parent_wallet_main_setting"
    @onClickRightMenu="viewModel.onClickWalletAdminPopup()">
    <div class="head_wallet_main">
      <p class="desc_total_amount">
        <strong class="txt_total_amount">{{ viewModel.model.wallet_amount | convertNumberToComma(false,'0') }}</strong>
        <span class="txt_total_unit">원</span>
      </p>
      <div class="group_box">
        <div class="box_money_item box_money" @click="viewModel.onClickMoneyInfo()">
          <span class="badge_word">M</span>
          <strong class="tit_money_item">{{ $poData.parent_wallet_main_money }}</strong>
          <p class="desc_amount">
            <strong class="txt_amount">{{ viewModel.model.money_amount | convertNumberToComma(false,'0','원') }}</strong>
          </p>
        </div>
        <div class="box_money_item box_money" @click="viewModel.onClickPointInfo()">
          <span class="badge_word">P</span>
          <strong class="tit_money_item">{{ $poData.parent_wallet_main_point }}</strong>
          <p class="desc_amount">
            <strong class="txt_amount">{{ viewModel.model.point_amount | convertNumberToComma(false,'0','원') }}</strong>
          </p>
        </div>
      </div>
      <Button
        btnStyle="primary"
        :text="$poData.parent_wallet_main_load_btn"
        @onClickBtn="sendInterface('moneyCharge')" />
    </div>
    <ButtonRouter
      :text="$poData.parent_wallet_main_heading_2"
      :isArrowRight="true"
      routeName="CouponList">
      <template v-if="viewModel.model.register_coupon_count > 0" v-slot:btn_right>
        <BadgeNum
          :value="viewModel.model.register_coupon_count"
          :size="24"/>
      </template>
    </ButtonRouter>
    <ButtonRouter
      :text="$poData.parent_wallet_main_heading_1"
      :isArrowRight="viewModel.model.wallet_transaction_count > 0"
      :routeName="viewModel.model.wallet_transaction_count > 0 ? 'ParentsTransList' : ''">
      <template v-if="viewModel.model.wallet_transaction_count > 0" v-slot:btn_right>
        <span class="txt_router">{{ $poData.parent_wallet_main_show_all }}</span>
      </template>
    </ButtonRouter>
    <template v-if="viewModel.model.wallet_transaction_count > 0">
      <ListItem
        v-for="(itemData, index) in viewModel.model.wallet_transaction_list"
        :key="`transItem${index}`"
        :padding="17"
        :thumbNail="{
          size: 40,
          url : itemData.profile_image_url
        }"
        :title="itemData.title"
        :leftSubText="itemData.sub_title"
        :leftBadge="{
          value : itemData.label_display_name,
          type : 'sub1'
        }"
        :rightSubText="itemData.amount | convertNumberToComma(false,'0','원')"
        :isRightSubTextColor="itemData.amount >= 0 ? 'red' : ''"
        @onClickItem="viewModel.onClickDetail(itemData.transaction_uid)">
      </ListItem>
    </template>
    <template v-else>
      <p class="desc_empty">{{ $poData.parent_wallet_main_no_trans }}</p>
    </template>
    <template v-slot:popup>
      <Layer
        class="box_drap_wallet_admin"
        :title="$poData.parent_wallet_adj_actionsheet_title"
        v-if="viewModel.isWalletAdminPopup"
        @onClickClose="viewModel.onClickWalletAdminPopupClose()">
        <Button
          btnStyle="secondary_tonal"
          :text="$poData.parent_wallet_adj_actionsheet_btn_1"
          @onClickBtn="onClickPageMoveBankAccounts()" />
        <Button
          btnStyle="secondary_tonal"
          :text="$poData.parent_wallet_adj_actionsheet_btn_2"
          @onClickBtn="onClickPageMoveWithdrawMoney()" />
      </Layer>
      <Layer
        :title="$poData.money_helpersheet_title"
        v-if="viewModel.isMoneyInfoPopup"
        @onClickClose="viewModel.onClickMoneyInfoClose()">
        <p class="desc_drop" v-html="$poData.money_helpersheet_desc"></p>
      </Layer>
      <Layer
        :title="$poData.point_helpersheet_title"
        v-if="viewModel.isPointInfoPopup"
        @onClickClose="viewModel.onClickPointInfoClose()">
        <p class="desc_drop" v-html="$poData.point_helpersheet_desc"></p>
      </Layer>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Button from '@/components/common/button/Button';
import ButtonRouter from '@/components/common/button/ButtonRouter';
import BadgeNum from '@/components/common/badge/BadgeNum';
import ListItem from '@/components/common/list/ListItem';
import Layer from '@/components/layout/popup/Layer';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import WalletMainViewModel from '@/views/wallet/viewModel/WalletMainViewModel'

export default {
  name: 'WalletMain',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    Button,
    ButtonRouter,
    BadgeNum,
    ListItem,
    Layer,
  },
  beforeMount(){
    this.viewModel.userType = 'PARENTS';
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new WalletMainViewModel(),
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isWalletAdminPopup){
        this.viewModel.onClickWalletAdminPopupClose();
      }
      else if(this.viewModel.isMoneyInfoPopup){
        this.viewModel.onClickMoneyInfoClose();
      }
      else if(this.viewModel.isPointInfoPopup){
        this.viewModel.onClickPointInfoClose();
      }
      else{
        this.webEnd();
      }
    },
    onClickPageMoveBankAccounts(){
      this.viewModel.onClickWalletAdminPopupClose();
      this.pageMove('bankAccounts');
    },
    onClickPageMoveWithdrawMoney(){
      this.viewModel.onClickWalletAdminPopupClose();
      this.pageMove('withdrawMoney');
    },
  }
}
</script>

<style scoped>
.head_wallet_main{padding:36px 20px 24px}
.head_wallet_main .desc_total_amount{text-align:center}
.head_wallet_main .desc_total_amount .txt_total_amount{display:inline-block;font-weight:700;font-size:40px;line-height:48px;color:#111;vertical-align:top}
.head_wallet_main .desc_total_amount .txt_total_unit{display:inline-block;margin:8px 0 4px 2px;font-weight:700;font-size:28px;line-height:36px;color:#111;vertical-align:top}


.badge_word{display:inline-block;width:24px;height:24px;border-radius:100%;font-family:'Poppins';font-weight:700;font-size:14px;line-height:24px;color:#111;text-align:center;background-color:#BEA9ED;vertical-align:top}

.group_box{display:flex;flex-wrap:nowrap;margin-top:28px}
.group_box .box_money_item{display:inline-block;width:calc(50% - 8px);padding:16px;border-radius:8px;background-color:#F3F1F1;vertical-align:top;box-sizing:border-box}
.group_box .box_money_item + .box_money_item{margin-left:16px}
.group_box .tit_money_item{display:inline-block;margin:1px 0 1px 6px;font-weight:600;font-size:14px;line-height:22px;vertical-align:top}
.group_box .desc_amount{margin-top:12px;font-weight:700;font-size:18px;line-height:28px;color:#111;vertical-align:top}
.group_box + button{margin-top:24px}

.box_drap_wallet_admin >>> .head_drop{padding-bottom:24px}
.box_drap_wallet_admin >>> .btn_secondary_tonal + .btn_secondary_tonal{margin-top:16px}
</style>